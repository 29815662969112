import React, { useState, useEffect } from 'react'
import Avatar from 'react-avatar'
import ClipLoader from 'react-spinners/ClipLoader'
import { FollowersModal, UserStatus, Navigation } from '../../components'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { api_url } from '../../config'
import routes from '../../routes'

export default function UserPreview() {
  const [user, setUser] = useState(null)
  const [avatar, setAvatar] = useState(null)
  const [albumsTotal, setAlbumsTotal] = useState(0)
  const [followers, setFollowers] = useState([])
  const [following, setFollowing] = useState([])
  const [followingModalIsOpen, setFollowingModalIsOpen] = useState(false)
  const [followersModalIsOpen, setFollowersModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [followersPage, setFollowersPage] = useState(1)
  const [followingPage, setFollowingPage] = useState(1)
  const [followersTotal, setFollowersTotal] = useState(0)
  const [followingTotal, setFollowingTotal] = useState(0)
  const [nextFollowersUrl, setNextFollowersUrl] = useState(null)
  const [nextFollowingUrl, setNextFollowingUrl] = useState(null)
  const [loadingFollows, setLoadingFollows] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { id } = useParams()
  const token = axios.defaults.headers.common.Authorization

  useEffect(() => {
    setFollowersModalIsOpen(false)
    setFollowingModalIsOpen(false)
    setFollowing([])
    setFollowers([])
    setFollowingPage(1)
    setFollowersPage(1)
    setNextFollowersUrl(null)
    setNextFollowingUrl(null)
    setLoading(true)
    // setFollowersTotal(0);
    // setFollowingTotal(0);
  }, [id, user])

  const handleOpenFollowers = () => {
    if (followers && followers.length > 0) {
      setFollowersModalIsOpen(true)
    }
  }

  const handleOpenFollowing = () => {
    if (following && following.length > 0) {
      setFollowingModalIsOpen(true)
    }
  }

  const handleCloseFollowingModal = () => {
    setFollowingModalIsOpen(false)
  }

  const handleCloseFollowersModal = () => {
    setFollowersModalIsOpen(false)
  }

  const loadMoreFollowers = () => {
    if (
      nextFollowersUrl &&
      !loadingFollows &&
      followers.length < followersTotal
    ) {
      setFollowersPage((prevState) => prevState + 1)
    }
  }

  const loadMoreFollowings = () => {
    if (
      nextFollowingUrl &&
      !loadingFollows &&
      following.length < followingTotal
    ) {
      setFollowingPage((prevState) => prevState + 1)
    }
  }

  useEffect(() => {
    if (token) {
      setLoading(true)
      axios
        .get(`${api_url}/profile/get?id=${id}`)
        .then((response) => {
          setUser(response.data.user)
          setAlbumsTotal(response.data.user.albums_count)
          setLoading(false)
          console.log('usera', response.data)
        })
        .catch((err) => console.log(err.response))

      axios
        .get(`${api_url}/profile/avatar/${id}`)
        .then((response) => {
          setAvatar(response.data)
        })
        .catch((err) => {
          console.log(err.response)
          setAvatar('no avatar')
        })
    }
  }, [token, id])

  // load followers
  useEffect(() => {
    setLoadingFollows(true)
    if (user) {
      axios
        .get(`${api_url}/profile/followers/${user.id}?page=${followersPage}`)
        .then((response) => {
          if (followers.length > 0) {
            setFollowers((prevState) => [
              ...prevState,
              ...response.data.followers.data,
            ])
          } else {
            setFollowers(response.data.followers.data)
          }
          setLoadingFollows(false)
          setFollowersTotal(response.data.followers.total)
          setNextFollowersUrl(response.data.followers.next_page_url)
        })
        .catch((err) => console.log(err.response.data))

      axios
        .get(`${api_url}/profile/following/${user.id}?page=${followingPage}`)
        .then((response) => {
          if (following.length > 0) {
            setFollowing((prevState) => [
              ...prevState,
              ...response.data.followers.data,
            ])
          } else {
            setFollowing(response.data.followers.data)
          }
          setLoadingFollows(false)
          setFollowingTotal(response.data.followers.total)
          setNextFollowingUrl(response.data.followers.next_page_url)
          console.log('fing', response.data)
        })
        .catch((err) => console.log(err))
    }
    // eslint-disable-next-line
  }, [user, id, followingPage, followersPage])

  return (
    <div className="profile">
      <div className="container">
        <div className="wrap userPreview__wrap">
          {user && avatar && !loading ? (
            <>
              <Navigation
                prevPage="Users"
                currentPage={user.userName}
                prevRoute={routes.users}
              />
              <div style={{ position: 'absolute', right: '29px', top: '33px' }}>
                <UserStatus
                  statusCode={user.status}
                  onShow={() => setShowModal(true)}
                  show={showModal}
                  onClose={() => setShowModal(false)}
                  id={user.id}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '120px',
                  }}
                >
                  <Avatar
                    size={150}
                    name={user.fullName}
                    round="50%"
                    maxInitials={2}
                    color="#050138"
                    src={avatar}
                  />
                </div>
                <div className="profile__user">
                  <div className="profile__user-name">
                    <h3 className="header header--two">{user.userName}</h3>
                  </div>
                  <div className="profile__user-info">
                    <span>
                      <span className="text--bold">{albumsTotal}</span> posts
                    </span>
                    <button
                      className="text text--normal"
                      onClick={handleOpenFollowers}
                    >
                      <span className="text--bold">{followersTotal}</span>{' '}
                      followers
                    </button>
                    <button
                      className="text text--normal"
                      onClick={handleOpenFollowing}
                    >
                      <span className="text--bold">{followingTotal}</span>{' '}
                      following
                    </button>
                    <h5 className="text text--normal text--bold profile__user-fullName">
                      {user.fullName}
                    </h5>
                    <p className="text text--normal profile__user-bio">
                      {user.bio}
                    </p>
                  </div>
                  <div className={`form-group userPreview__form-group`}>
                    <label className="label userPreview__label">Email</label>
                    <input
                      type="email"
                      className="formInput userPreview__formInput"
                      name="email"
                      value={user.email}
                      disabled
                    />
                  </div>
                  <a
                    rel="noreferrer"
                    href={`https://testigram.ai/userProfile/${id}`}
                    className="link link--underlined text--bold"
                    target="_blank"
                  >
                    View on Testigram
                  </a>
                </div>
              </div>

              <ClipLoader color={'#050138'} loading={loading} size={75} />
            </>
          ) : (
            <div style={{ margin: 'auto' }}>
              <ClipLoader color={'#050138'} loading={true} size={75} />
            </div>
          )}
        </div>
      </div>
      <FollowersModal
        followers={following}
        header={'Following'}
        modalIsOpen={followingModalIsOpen}
        onCloseModal={handleCloseFollowingModal}
        isMyProfile={false}
        removeText={'Unfollow'}
        loadingFollows={loadingFollows}
        nextPageUrl={nextFollowingUrl}
        loadMoreFollows={loadMoreFollowings}
      />
      <FollowersModal
        followers={followers}
        header={'Followers'}
        modalIsOpen={followersModalIsOpen}
        onCloseModal={handleCloseFollowersModal}
        isMyProfile={false}
        removeText={'Remove'}
        loadingFollows={loadingFollows}
        nextPageUrl={nextFollowersUrl}
        loadMoreFollows={loadMoreFollowers}
      />
    </div>
  )
}
