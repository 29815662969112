import React, { useState, useEffect } from "react";
import {
  Navigation,
  Button,
  Audio,
  Video,
  ModalWindow,
} from "../../components";
import { useDropzone } from "react-dropzone";
import "./Adds.scss";
import { api_url, max_file_size } from "../../config";
import mediaIcon from "../../assets/img/admin/media-icon.png";
import cameraIcon from "../../assets/img/admin/camera.svg";
import { useFormik } from "formik";
import createAddSChema from "../../validationSchemas/createAddSchema";
import editIcon from "../../assets/img/edit-icon.svg";
import { useHistory } from "react-router-dom";
import axios from "axios";
import routes from "../../routes";
import ClipLoader from "react-spinners/ClipLoader";
import ReactQuill from "react-quill";

function nameLengthValidator(file) {
  console.log(file.size);
  if (file.size > max_file_size) {
    return {
      code: "file-to-big",
      message: `File cannot be larger than ${max_file_size / 1000 / 1000} Mb`,
    };
  } else if (
    file.type.includes("webm") ||
    file.name.includes(".jfif") ||
    file.name.includes(".svg")
  ) {
    return { code: "unavailible type", message: "Invalid file type" };
  }

  return null;
}

export default function CreateAdd() {
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);
  const [file, setFile] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false) 
  const [loading, setLoading] = useState(false);
  const [formInputFocused, setFormInputFocused] = useState(false);
  const history = useHistory();

  const handleCloseModal = () => {
    setModalIsOpen(false);
    history.push(routes.adds);
  };

    const handleCloseErrorModal = () => {
    setIsModalErrorOpen(false);
  };

  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } =
    useDropzone({
      maxFiles: 1,
      validator: nameLengthValidator,
      noClick: true,
      accept: "image/*,  video/*,  audio/*",
    });

  useEffect(() => {
    acceptedFiles.forEach((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      console.log(file);
    });
  }, [acceptedFiles]);

  useEffect(() => {
    const err = fileRejections.map((file) => {
      return file.errors[0].message;
    });
    setError(err[0]);
  }, [fileRejections]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].type.includes("image")) {
        console.log(acceptedFiles);
        setFile(acceptedFiles[0]);
        // addFile(acceptedFiles[0].preview);
      } else {
        console.log(acceptedFiles[0]);
      }
    }
  }, [acceptedFiles]);

  const formik = useFormik({
    initialValues: {
      addsName: "",
      addsDescription: "",
      addsUrl: "",
    },
    validationSchema: createAddSChema,
    onSubmit: (values) => {
      if (file && !loading) {
        setLoading(true);
        const formdata = new FormData();
        formdata.append("file", file);
        formdata.append("title", values.addsName);
        formdata.append("description", values.addsDescription);
        formdata.append("conversion_rate", 0);
        formdata.append("url", values.addsUrl);
        formdata.append("status", 0);
        axios
          .post(`${api_url}/admin/advertisements/create`, formdata)
          .then((response) => {
            console.log(response.data);
            setLoading(false);
            setModalIsOpen(true);
          })
          .catch((err) => {
            setIsModalErrorOpen(err.response.data.file[0])
            setLoading(false)  
          });
      } else {
        setError("File is required");
      }
    },
  });

  const formError = (error, value) => {
    if ((error && value) || (error && submited)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      ></div>
      <form
        encType="multipart/form-data"
        className="wrap createAdd__wrap"
        onSubmit={(values) => {
          setSubmited(true);
          formik.handleSubmit(values);
        }}
      >
        <Navigation
          prevPage="Ads"
          currentPage="Create New Ads"
          prevRoute={routes.adds}
        />
        <div
          style={{
            position: "absolute",
            right: "0",
            top: "-23px",
            transform: "translateY(-100%)",
          }}
        >
          <Button
            text="Cancel"
            contained={false}
            className="button button--outlined"
            onClick={() => history.goBack()}
          />
          <button
            className="button button--primary createAdd__save"
            type="submit"
          >
            {loading ? (
              <ClipLoader color={"#ffffff"} loading={loading} size={15} />
            ) : (
              "Save"
            )}
          </button>
        </div>
        <div className="adds__block">
          {!file ? (
            <div
              {...getRootProps({
                className: "dropzone filebox createAdd__dropzone ",
              })}
            >
              <input {...getInputProps()} />
              <img src={mediaIcon} alt="media" />
              <button
                type="button"
                className="createAdd__button"
                onClick={open}
              >
                <img src={cameraIcon} alt="camera" />
                <span>add an image</span>
              </button>
            </div>
          ) : (
            <div style={{ position: "relative" }}>
              {acceptedFiles[0] && acceptedFiles[0].type.includes("image") && (
                <div
                  style={{
                    position: "relative",
                    width: "468px",
                    height: "468px",
                    borderRadius: "15px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={acceptedFiles[0].preview}
                    alt="chosen"
                    className="chooseFilter__image"
                  />
                </div>
              )}
              {acceptedFiles[0] && acceptedFiles[0].type.includes("audio") && (
                <Audio file={acceptedFiles[0].preview} />
              )}
              {acceptedFiles[0] && acceptedFiles[0].type.includes("video") && (
                <Video file={acceptedFiles[0].preview} />
              )}
              <button
                className="link link--small "
                onClick={() => setFile(null)}
                style={{
                  marginLeft: "auto",
                  display: "block",
                  marginTop: "10px",
                }}
              >
                <img src={editIcon} alt="edit" />
                <span style={{ marginLeft: "5px" }}>Change file</span>
              </button>
            </div>
          )}
          {error && (
            <p
              className="text"
              style={{
                marginTop: "10px",
                textAlign: "center",
                color: "#da100b",
              }}
            >
              {error}
            </p>
          )}
          <div className="form__group">
            <span className="formNotification text--bold adds__label">
              External URL
            </span>
            <input
              type="text"
              className={`formInput ${
                formError(formik.errors.addsUrl, formik.values.addsUrl) &&
                "inputError"
              }`}
              name="addsUrl"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p className="formNotification">
              {formError(formik.errors.addsUrl, formik.values.addsUrl)
                ? formik.errors.addsUrl
                : "The indicated URL will redirect users to external resource."}
            </p>
          </div>
        </div>
        <div className="adds__block" style={{ marginLeft: "50px" }}>
          <div
            style={{
              display: "flex",
              width: "522px",
              justifyContent: "flex-end",
            }}
          >
            <div className="adds__card">
              <div className="adds__card-top text--bold">Created Date</div>
              <div className="adds__card-bottom">
                {new Date().toLocaleDateString("en-US")}
              </div>
            </div>
            <div className="adds__card">
              <div className="adds__card-top text--bold">Status</div>
              <div className="adds__card-bottom">
                <span className="status status--pending">Pending</span>
              </div>
            </div>
          </div>
          <div className="form__group">
            <span className="formNotification text--bold adds__label">
              Ads title
            </span>
            <input
              type="text"
              className={`formInput ${
                formError(formik.errors.addsName, formik.values.addsName) &&
                "inputError"
              }`}
              placeholder="enter ads title here"
              name="addsName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p className="formNotification">
              {formError(formik.errors.addsName, formik.values.addsName)
                ? formik.errors.addsName
                : " Maximum title length is 70 symbols.."}
            </p>
          </div>
          <div className="form__group">
            <span className="formNotification text--bold adds__label">
              Ads description
            </span>
            <ReactQuill
              onFocus={() => setFormInputFocused(true)}
              onBlur={() => setFormInputFocused(false)}
              theme={"snow"}
              name="addsDescription"
              className={`formInput ${
                formError(
                  formik.errors.addsDescription,
                  formik.values.addsDescription
                ) && "inputError"
              } ${formInputFocused && "formInput--focused"}`}
              style={{ fontSize: "16px", padding: "0" }}
              value={formik.values.addsDescription}
              placeholder="enter ads description here"
              onChange={(content, delta, source, editor) => {
                formik.setFieldValue(
                  "addsDescription",
                  content
                    .replace(/<img .*?>/g, "")
                    .replace(
                      "<p><br></p><p><br></p><p><br></p>",
                      "<p><br></p>"
                    ),
                  true
                );
              }}
              preserveWhitespace={true}
            />
            <p className="formNotification">
              {formError(
                formik.errors.addsDescription,
                formik.values.addsDescription
              )
                ? formik.errors.addsDescription
                : "Maximum description text length is 1500 symbols."}
            </p>
          </div>
        </div>
      </form>
      <ModalWindow
        modalWidth={380}
        textWidth={295}
        modalIsOpen={modalIsOpen}
        onCloseModal={handleCloseModal}
        header="New Ads Saved"
        text={`New ad ${formik.values.addsName} has been successfully added.`}
      />
      <ModalWindow
        modalWidth={380}
        textWidth={295}
        modalIsOpen={isModalErrorOpen}
        onCloseModal={handleCloseErrorModal}
        header="New Ads Failed"
        text={isModalErrorOpen}
      />
    </div>
  );
}
